import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";
import moment from "moment";

export const FetchStartupDetails = (slug) => {
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startup-details-by-slug/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            // console.log(data,"new Data")
            return { data: savedData?.data[0], status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        enabled: !!slug,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchStartupPitchDeck = (slug) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupPitchDeck-FetchStartupPitchDeck-FetchStartupPitchDeck-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-pitch-deck/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            // console.log(data,"new Data")
            return { data: savedData?.data, status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchProfileDetails = (isTokenAvailable) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchProfileDetails-FetchProfileDetails-FetchProfileDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/user-info`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            const personal_information = savedData?.personal_information
            const profile_picture = savedData?.profile_picture
            // console.log("savedData", savedData)
            let date = ""
            let month = ""
            let year = ""
            if (personal_information?.dob) {
                let dateOfBirth = moment(personal_information?.dob).format("MM-DD-YYYY")
                month = dateOfBirth.split("-")[0]
                date = dateOfBirth.split("-")[1]
                year = dateOfBirth.split("-")[2]
            }
            const savedValues = {
                // step 1
                first_name: personal_information?.first_name?.trim()?.length > 0 ? String(personal_information?.first_name) : '',
                last_name: personal_information?.last_name?.trim()?.length > 0 ? String(personal_information?.last_name) : '',
                title: personal_information?.title ? String(personal_information?.title) : '',
                dd: date,
                mm: month,
                yyyy: year,
                custom_image_url: profile_picture?.custom_image_url?.trim()?.length > 0 ? profile_picture?.custom_image_url : null,
                avatar_selected: profile_picture?.avatar_selected?.trim()?.length > 0 ? profile_picture?.avatar_selected : null,
                // mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                // phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                // country_id: savedData?.country_id ? savedData?.country_id : null,
                // state_id: savedData?.state_id ? savedData?.state_id : null,
                // city_id: savedData?.city_id ? savedData?.city_id : null,
                // stage_id: savedData?.stage_id ? savedData?.stage_id : "",
                // sector_id: savedData?.sector_id ? savedData?.sector_id : [],
                step: savedData?.step || 1,
                _id: savedData?._id,
                investor_id: savedData?.investor_id
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return isTokenAvailable && verifyToken(err)
        },
    })
}

export const SavePersonalInfo = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveToList = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/add_list`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveInterest = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/show_interested`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveUserType = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/show_interested`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchStatus = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-investor-info`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveFeedback = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/submit-feedback`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupDocuments = (startup_id) => {
    return useMutation(
        (startup_id) => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/get_all_round_documents/${startup_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
}

export const SaveView = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/add-investor-view`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorDetails = (slug) => {
    return useQuery(
        ['master-FetchInvestorDetails-FetchInvestorDetails-FetchInvestorDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL_FOR_INVESTOR}/investors/profile/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            // console.log(data,"new Data")
            return { data: savedData?.data[0], status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}